.listaGabaritos{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px;
    color: #000;
}
.listaGabaritos > li > a {
    color: #000;
    display: inline-grid;
    text-align: center;
    font-weight: 700;
}
.listaGabaritos > li {
    margin: 8px;
}
.tituloPagina{
    margin: 16px;
    font-family: oswald, Verdana, Geneva, sans-serif;
}
.tituloPagina > div > a{
    color: #000;
}
.nomeTituloPagina{
    font-size: 52px;
    font-weight: bold;
}